.containerall {
    background: linear-gradient(0deg, rgba(2, 0, 36, 1) 21%, rgba(85, 0, 170, 1) 124%, rgba(85, 0, 170, 1) 400%);
}

.desportfolio {
    margin-top: 60px;
    padding-bottom: 50px;
}

.carousel-root {
    outline: none;
    width: 850px !important;
    margin: auto;
    padding-top: 20px;
    text-align: center;
}

.article2 {
    margin-bottom: 60px;
}

.article__title2 {
    margin-bottom: 10px;
}

.pinned-message__text,
.project-options {
    text-align: center;
    justify-content: center;
}


@media screen and (max-width: 1023px) {
    .carousel-root {
        outline: none;
        width: 700px !important;
        margin: auto;
        padding-top: 20px;
    }

    .project-options {
        justify-content: center;
    }
}

@media screen and (max-width: 849px) {
    .carousel-root {
        outline: none;
        width: 600px !important;
        margin: auto;
        padding-top: 20px;
    }

    .project-options {
        justify-content: center;
    }
}

@media screen and (max-width: 742px) {
    .carousel-root {
        outline: none;
        width: 500px !important;
        margin: auto;
        padding-top: 20px;
    }

    .project-options {
        justify-content: center;
    }
}

@media screen and (max-width: 599px) {
    .carousel-root {
        outline: none;
        width: 500px !important;
        margin: auto;
        padding-top: 20px;
    }

    .project-options {
        justify-content: inherit;
    }
}

@media screen and (max-width: 535px) {
    .carousel-root {
        outline: none;
        width: 300px !important;
        margin: auto;
        padding-top: 20px;
    }

    .fa-arrow-up {
        background-color: transparent;
    }

    .project-options {
        justify-content: inherit;
    }
}

@media screen and (max-width: 360px) {
    .carousel-root {
        outline: none;
        width: 300px !important;
        margin: auto;
        padding-top: 20px;
    }

    .img-principal {
        margin-top: -200px;
    }

    .project-options {
        justify-content: inherit;
    }
}
