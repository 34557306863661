.navbar {
  background: linear-gradient(180deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 0px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-logo:hover {
  /* border-bottom: 4px solid #5500aa;
  transition: all 0.2s ease-in-out; */
  color: #5500aa;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.fav-navbar {
  padding-right: 10px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  /* border-bottom: 4px solid #5500aa;
  transition: all 0.2s ease-out; */
  color: #5500aa;
}

.fa-bars {
  color: #fff;
}
.fa-bars:hover {
  color: #5500aa;
}

.fa-times:hover {
  color: #5500aa;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.icons-navbar {
  display:flex;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .navbar-logo {
    font-size: 20px;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
  }

  .nav-menu.active {
    background: linear-gradient(to bottom,#1B1919 , #5500AA);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    justify-content: center;
  }

  .nav-links {
    color: #ffffff;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #400180;
    color: #ffffff;
    border-radius: 0;
    border-bottom: 1px solid #ffffff;

  }

  .navbar-logo {
    position: absolute;
    margin-left: 30px;
    /* top: 0; */
    left: 0;
    /* transform: translate(25%, 50%); */
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

  .icons-navbar {
    display:none;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 30px;
    margin-top: -90px;
  }

  .hero-container > p {
    font-size: 15px;
  }

  

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }

  .img-principal {
    width: 150px;
    height: 180px;
    margin-bottom: 100px;
  }

  .linkPortfolio {
    text-align: center;
    font-size: 13px;
}

.icons-navbar {
  display:none;
}
}
