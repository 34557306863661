.footer-container {
  /* background: linear-gradient(0deg, rgba(2,0,36,1) 84%, rgba(85,0,170,1) 21%, rgba(85,0,170,1) 100%); */
  background-color: rgba(2,0,36,1);
   /* background: url('/images/img-7.jpg') center center/cover no-repeat; */
  /* height: 91.8vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  margin-top: -100px;
}

.footer {
  padding-top: 10px;
  padding-bottom: 10px;
}

.fav-navbar {
  margin-left: 10px;
  height: 30px;
  width: 40px;
}

.fa-twitter, .fa-linkedin, .fa-github, .fa-envelope, .fa-whatsapp {
  margin: 5px;
}

.fa-twitter:hover, .fa-linkedin:hover, .fa-github:hover, .fa-envelope:hover, .fa-whatsapp:hover {
  color: #5500aa;
}


.footer-subscription {
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}

.footer-subscription > p {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-logo a:hover {
  color: #5500aa;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media {
  max-width: 1000px;
  width: 100%;
}

.social-media-wrap {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  margin: 40px auto 0 auto;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

@media screen and (max-width: 768px) {

  .footer-container {
    height: 0px;
  }

  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
    justify-content: space-between;

  }

  .footer-logo {
    margin-bottom: 10px;
  }

  .social-media-wrap {
    flex-direction: column;
    margin-top: 85px;
  }
}

@media screen and (max-width: 360px) {

  .social-media-wrap {
    flex-direction: column;
    margin-top: 25px;
  }
}
