.container {
  width: 100%;
  max-width: 850px;
  padding: 0 20px;
  margin: 0 auto;
}

.container-all {
  background: linear-gradient(0deg, rgba(2, 0, 36, 1) 21%, rgba(85, 0, 170, 1) 124%, rgba(85, 0, 170, 1) 400%);
}
.background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -100;
}
.blur {
  display: none;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
}
.blur--active {
  display: block;
}
.overflow--hidden {
  overflow: hidden;
}
::selection {
  background: rgba(196, 53, 232, 0.4);
  color: #fff;
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
body {
  margin: 0;
  font-family: "Saira", sans-serif;
  color: #fff;
  background: #000;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
a {
  text-decoration: none;
}
button {
  border: none;
  outline: none;
}
img {
  -webkit-user-select: none;
  user-select: none;
}
.fadeIn {
  animation: fadeIn 800ms ease-in forwards;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.slideUp {
  animation: slideUp 800ms cubic-bezier(0.04, 0.86, 1, 0.99) forwards;
}
@keyframes slideUp {
  0% {
    transform: translateY(200%);
  }
  to {
    transform: translateY(0);
  }
}
.delay-1 {
  animation-delay: 100ms;
}
.duration-1 {
  animation-duration: 100ms;
}
.delay-2 {
  animation-delay: 200ms;
}
.duration-2 {
  animation-duration: 200ms;
}
.delay-3 {
  animation-delay: 300ms;
}
.duration-3 {
  animation-duration: 300ms;
}
.delay-4 {
  animation-delay: 400ms;
}
.duration-4 {
  animation-duration: 400ms;
}
.delay-5 {
  animation-delay: 500ms;
}
.duration-5 {
  animation-duration: 500ms;
}
.delay-6 {
  animation-delay: 600ms;
}
.duration-6 {
  animation-duration: 600ms;
}
.delay-7 {
  animation-delay: 700ms;
}
.duration-7 {
  animation-duration: 700ms;
}
.delay-8 {
  animation-delay: 800ms;
}
.duration-8 {
  animation-duration: 800ms;
}

.project {
  width: 100%;
  max-width: 400px;
  height: 225px;
  overflow: hidden;
  cursor: pointer;
}
.project .screen-bar {
  height: 25px;
  position: relative;
  background: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project .screen-bar__options {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  position: absolute;
  left: 5px;
}
.project .screen-bar__title {
  display: inline;
  font-size: 0.75em;
  font-weight: 500;
}
.project .main-screen {
  width: 100%;
  height: 200px;
}
.project .main-screen img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.project__options {
  width: 100%;
  height: 200px;
  transition: transform 300ms;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
}

.project__options .options {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.project__options .options a {
  margin-bottom: 20px;
  text-decoration: none;
  font-size: 1em;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
}
.project__options .options a:last-child {
  margin: 30px 0 0;
  font-size: 0.9em;
  font-weight: 400;
  color: #5500aa;
}
.project__options .options a:last-child img {
  width: 12px;
  margin-left: 5px;
}
.project__options .options a:hover .options__github img {
  transform: translateX(0);
}
.project__options .options a:hover .options__icon {
  opacity: 1;
}
.project__options .options__github,
.project__options .options span {
  overflow: hidden;
}

.project__options .options__github:hover,
.project__options .options span:hover {
  overflow: hidden;
  color: #5500aa;
}

.project__options .options__github {
  display: grid;
  width: 15px;
  height: 15px;
  margin-left: 10px;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
}
.project__options .options__github img {
  opacity: 1;
  margin-left: 0;
  transform: translateX(-30px);
  transition: all 200ms;
}
.project__options .options__icon {
  opacity: 0.5;
  margin-left: 10px;
  transition: all 200ms;
}
.project:hover .project__options {
  transform: translateY(-200px);
}
.article {
  display: grid;
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
  gap: 20px;
  color: rgba(255, 255, 255, 0.6);
}
.article__title {
  margin: 0;
  font-weight: 600;
  color: #fff;
}
.article__text {
  margin: 0;
}
.article__link {
  display: inline-flex;
  font-weight: 500;
  text-decoration: underline;
  -webkit-text-decoration-color: rgba(255, 255, 255, 0.4);
  text-decoration-color: rgba(255, 255, 255, 0.4);
  align-items: center;
  color: #fff;
}
.article__link img {
  width: 15px;
  height: 15px;
  transition: all 200ms;
}
.article__link:hover {
  -webkit-text-decoration-color: #fff;
  text-decoration-color: #fff;
}
.article__link:hover img {
  transform: translateX(0);
}
.article__link .social-menu__icon {
  margin-left: 5px;
}
.article__link > img {
  opacity: 0.4;
}
.article__link:hover > img {
  opacity: 1;
}
.list__item {
  display: grid;
  grid-template-columns: 15px 1fr;
  align-items: center;
  gap: 20px;
}
.link-list img {
  margin-left: 0;
}
.link-list span {
  margin-left: 20px;
}
.link-list .social-menu__icon {
  margin-left: 0;
}
.index-main-section {
  align-self: center;
}
.hero {
  text-align: center;
}
.hero__picture {
  width: 150px;
}
.hero__title {
  margin: 25px 0 10px;
}
.hero__title h1 {
  margin: 0;
  line-height: 1.2em;
  transform: translateY(200%);
  font-size: 2.5em;
  font-weight: 500;
}
.hero__description {
  margin: 0 0 30px;
}
.hero__description p {
  margin: 0;
  transform: translateY(200%);
  font-size: 1.125em;
}
.hero__cta a {
  display: inline-block;
  text-decoration: none;
  transform: translateY(200%);
  font-size: 0.9em;
  color: #c435e8;
}
.hero__cta a img {
  margin-left: 5px;
  vertical-align: middle;
  opacity: 0.4;
  transition: all 200ms;
}
.hero__cta a:hover img {
  opacity: 1;
}
.index-footer {
  padding: 40px 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}
.index-footer__text p {
  margin: 0;
  transform: translateY(200%);
  font-size: 0.9em;
}
.index-footer a {
  font-weight: 600;
  text-decoration: underline;
  -webkit-text-decoration-color: rgba(255, 255, 255, 0.4);
  text-decoration-color: rgba(255, 255, 255, 0.4);
  color: #fff;
}
.index-footer a:hover {
  -webkit-text-decoration-color: #fff;
  text-decoration-color: #fff;
}
.pinned-message {
  margin-bottom: 40px;
  overflow-y: hidden;
}
.pinned-message p {
  margin: 0;
  font-size: 1em;
  transform: translateY(200%);
}
.projects {
  display: grid;
  gap: 20px;
  justify-content: center;
  opacity: 0;
}
.about-header__title {
  text-align: left;
}
.about-header__title h3 {
  margin: 0;
  line-height: 1.2em;
  text-shadow: 0 0 2px #c435e8;
  transform: translateY(200%);
  font-size: 1.5em;
  font-weight: 500;
}
.about-me,
.project-info {
  display: grid;
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
  gap: 40px;
  opacity: 0;
}
.arrow {
  display: block;
  width: 25px;
  height: 30px;
  position: relative;
}
.arrow:after,
.arrow:before {
  content: "";
  width: 100%;
  height: 3px;
  position: absolute;
  left: 0;
  transform-origin: left;
  transition: all 300ms;
  background: #fff;
}
.arrow:before {
  top: 15px;
  transform: rotateZ(-45deg);
}
.arrow:after {
  bottom: 14px;
  transform: rotateZ(45deg);
}
.arrow:hover:before {
  transform: rotateZ(-60deg);
}
.arrow:hover:after {
  transform: rotateZ(60deg);
}
.arrow.active:after,
.arrow.active:before {
  transform: rotateZ(0deg);
}
.arrow.go:after,
.arrow.go:before {
  transform: translate(-200%);
  opacity: 0;
}
.project-options {
  display: grid;
  margin-bottom: 40px;
  gap: 10px;
  opacity: 0;
}
.button {
  height: 50px;
  width: 100%;
  border-radius: 20px;
  transform: rotateX(15deg);
  transition: all 200ms;
  border-bottom: 6px solid #262626;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  font-weight: 500;
  color: #fff;
}
.button:first-child {
  background: #1a1a1a;
}
.button:last-child {
  background: #74108d;
  border-color: #4f0b60;
}
.button img {
  margin-left: 5px;
}
.button:hover {
  border-width: 3px;
  transform: rotateX(20deg);
}
.button:active {
  border-width: 0;
  transform: rotateX(25deg);
}
.slideshow {
  margin-bottom: 30px;
}
.slideshow .main-screen {
  position: relative;
}
.slideshow .arrow {
  width: 15px;
}
.slideshow .arrow:after,
.slideshow .arrow:before {
  height: 2px;
  box-shadow: none;
  background: #b3b3b3;
}
.slideshow__control {
  display: flex;
  width: 30%;
  height: 100%;
  padding: 0 10px;
  align-items: center;
  position: absolute;
  top: 0;
  opacity: 0.2;
  transition: opacity 300ms ease-out;
  cursor: pointer;
}
.slideshow__control.prev {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), transparent);
}
.slideshow__control.next {
  justify-content: flex-end;
  right: 0;
  background: linear-gradient(to left, rgba(0, 0, 0, 0.8), transparent);
}
.slideshow__control.next .arrow {
  transform: rotateY(180deg);
}
.slideshow__control:hover {
  opacity: 1;
}
.slideshow__control:hover .arrow:before {
  transform: rotateZ(-60deg);
}
.slideshow__control:hover .arrow:after {
  transform: rotateZ(60deg);
}
.slideshow__image {
  opacity: 0;
}
.bullets {
  display: grid;
  grid-template: 15px / repeat(3, 15px);
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  justify-items: center;
}
.bullets .bullet {
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #333333;
  transition: all 100ms ease-in;
  cursor: pointer;
}
.bullets .bullet:hover {
  height: 12px;
  width: 12px;
  background: gray;
}
.bullets .bullet.active {
  height: 15px;
  width: 15px;
  background: #cccccc;
}
.grid {
  display: grid;
  width: 100%;
  min-height: 100vh;
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
}
.grid-boundaries {
  display: none;
  grid-column: margin2-start/margin3-end;
  border-left: 1px solid rgba(196, 53, 232, 0.2);
  border-right: 1px solid rgba(196, 53, 232, 0.2);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -10;
}
.index-grid {
  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.logo__text {
  font-size: 1.125em;
  font-weight: 600;
  color: #fff;
}
.menu-container {
  padding-right: 25px;
  position: fixed;
  right: 10px;
  top: 70px;
  bottom: 0;
  z-index: 30;
  text-align: right;
  overflow-y: auto;
}
.menu-container a {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  transform: translateY(200%);
  will-change: transform;
}
.menu-container img {
  transition: all 200ms;
}
.menu {
  font-size: 1.5em;
  font-weight: 600;
}
.menu__item,
.social-menu__item {
  margin: 0 0 15px;
  overflow: hidden;
}
.menu a {
  color: #fff;
}
.menu a img {
  margin-left: 10px;
  opacity: 0.4;
}
.menu a:hover img {
  opacity: 1;
}
.social-menu {
  font-size: 1.125em;
  font-weight: 600;
}
.social-menu .twitter {
  color: #fff;
}
.social-menu .github {
  color: #fff;
}
.social-menu .linkedin {
  color: #fff;
}

.social-menu .twitter:hover {
  color: #5500aa;
}
.social-menu .github:hover {
  color: #5500aa;
}
.social-menu .linkedin:hover {
  color: #5500aa;
}

.social-menu__icon,
.social-menu span {
  overflow: hidden;
}
.social-menu__icon {
  display: grid;
  width: 15px;
  height: 15px;
  margin-left: 10px;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
}
.social-menu__icon img {
  transform: translateX(-30px);
}
.social-menu a:hover img {
  transform: translateX(0);
}
.social-menu a:hover {
  transition: color 200ms;
  color: #fff;
}
.menu--active {
  display: block;
}
.header {
  text-align: center;
}
.header__title {
  margin: 25px 0 40px;
  overflow-y: hidden;
}
.header__title h1 {
  margin: 0;
  line-height: 1.2em;
  text-shadow: 0 0 2px #c435e8;
  transform: translateY(200%);
  font-family: monospace;
  font-size: 2.5em;
  font-weight: 500;
}
.footer {
  padding: 50px 0 20px;
}
.footer__options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  column-gap: 50px;
}
.footer__options a {
  display: inline-flex;
  align-items: center;
  transform: translateY(200%);
}
.footer .menu {
  font-size: 0.9em;
}
.footer .menu__item:last-child {
  margin-bottom: 0;
}
.footer .menu img {
  margin-left: 5px;
}
.footer .social-menu {
  text-align: right;
  font-size: 0.9em;
}
.footer .social-menu__item:last-child {
  margin-bottom: 0;
}
.footer .social-menu__icon {
  width: 10px;
  height: 10px;
  margin-left: 5px;
  column-gap: 10px;
}
.footer .social-menu__icon img {
  transform: translateX(-20px);
}
.footer__copyright {
  display: grid;
  margin-top: 40px;
  text-align: center;
  overflow-y: hidden;
  color: rgba(255, 255, 255, 0.6);
}
.footer__copyright .made-with {
  font-size: 0.75em;
  transform: translateY(200%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__copyright .made-with img {
  margin-left: 5px;
}
.footer__copyright .copyright {
  transform: translateY(200%);
  font-size: 0.6em;
}
.footer img {
  width: 10px;
  transition: all 200ms;
}
@media screen and (min-width: 400px) {
  .project {
    height: 295px;
  }
  .project .main-screen,
  .project__options {
    height: 270px;
  }
  .project:hover .project__options {
    transform: translateY(-270px);
  }
}
@media screen and (min-width: 600px) {
  .project-options {
    grid-template-columns: repeat(2, 250px);
  }
  .slideshow__screen {
    height: 395px;
    max-width: unset;
  }
  .slideshow__screen .main-screen {
    height: 380px;
  }
}
@media screen and (min-width: 768px) {
  .about-me,
  .header,
  .hero,
  .index-footer,
  .pinned-message,
  .project,
  .project-info,
  .project-options {
    font-size: 18px;
  }
  .footer {
    font-size: 17px;
  }
  .hero__picture {
    width: 200px;
  }
  .slideshow__screen {
    height: 495px;
  }
  .slideshow__screen .main-screen {
    height: 470px;
  }
  .slideshow__screen .arrow {
    width: 25px;
  }
}
@media screen and (min-width: 850px) {
  .projects {
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
  }
  .footer .menu {
    display: flex;
  }
  .footer .menu__item {
    margin: 0 20px 0 0;
  }
  .footer .menu__item:last-child {
    margin: 0;
  }
  .footer .social-menu {
    display: flex;
    justify-content: flex-end;
  }
  .footer .social-menu__item {
    margin: 0 0 0 20px;
  }
  .footer .social-menu__item:first-child {
    margin: 0;
  }
  .slideshow {
    grid-template-columns: unset;
    justify-content: unset;
  }
  .slideshow__screen {
    height: 560px;
  }
  .slideshow__screen .main-screen {
    height: 545px;
  }
  .slideshow__control {
    padding: 0 20px;
  }
}
@media screen and (min-width: 1024px) {
  ::-webkit-scrollbar {
    width: 0.4em;
  }
  ::-webkit-scrollbar-track {
    background: #000;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(85, 0, 170);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(145, 102, 189);
  }
  .grid,
  .index-grid {
    max-width: 1900px;
    margin: 0 auto;
    position: relative;
    grid-template-columns: [margin1-start] 100px [margin1-end margin2-start] 100px [margin2-end main-start] minmax(
        auto,
        850px
      ) [main-end margin3-start] 100px [margin3-end margin4-start] 100px [margin4-end];
    justify-content: center;
  }
  .grid-boundaries {
    display: block;
  }
  .container {
    padding: 0;
  }

  .logo {
    display: flex;
    justify-content: center;
    grid-area: auto/margin1-start/auto/margin2-end;
  }
  .logo__text {
    position: fixed;
    top: 55px;
    z-index: 10;
  }

  .menu-container {
    padding-right: 70px;
    top: 120px;
    right: unset;
  }
  .header {
    grid-area: auto/margin2-start/auto/margin3-end;
    border-bottom: 1px solid rgba(196, 53, 232, 0.2);
  }
  .header__title {
    margin: 40px 0;
  }
  .about-header {
    grid-area: auto/main-start/auto/main-end;
    border: none;
  }
  .about-header__title {
    padding: 0;
    margin: 40px 0 10px;
  }
  .footer,
  .main-section,
  .pinned {
    grid-area: auto/main-start/auto/main-end;
  }
  .about-me,
  .header,
  .hero,
  .index-footer,
  .pinned-message,
  .project-info,
  .project-options,
  .project__options {
    font-size: 20px;
  }
  .pinned-message {
    margin: 30px 0 40px;
  }
  .about-pinned-message {
    margin: 10px 0 50px;
  }
  .footer {
    font-size: 18px;
  }
  .footer img {
    width: 12px;
  }
  .footer .social-menu__icon {
    width: 12px;
    height: 12px;
    column-gap: 12px;
  }
  .footer .social-menu__icon img {
    transform: translateX(-24px);
  }
  .project__options .options__github {
    width: 20px;
    height: 20px;
    column-gap: 20px;
  }
  .project__options .options__github img {
    transform: translateX(-40px);
  }
  .project__options img {
    width: 20px;
  }
  .project__options .options a:last-child img {
    width: 15px;
  }
  .index-grid {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .index-main-section {
    padding: 25px 0 0;
    grid-area: 2 / main-start/3/main-end;
  }
  .hero__picture {
    width: 250px;
  }
  .index-footer {
    grid-area: 3 / main-start/-1/main-end;
  }
  .slideshow__screen {
    height: 395px;
    max-width: unset;
  }
  .slideshow__screen .main-screen {
    height: 370px;
  }
  .slideshow__screen .arrow {
    width: 25px;
  }
}
@media screen and (min-width: 1100px) {
  .slideshow__screen {
    height: 495px;
  }
  .slideshow__screen .main-screen {
    height: 470px;
  }
}
@media screen and (min-width: 1240px) {
  .slideshow__screen {
    height: 560px;
  }
  .slideshow__screen .main-screen {
    height: 545px;
  }
}
.footerhover:hover {
  color: #5500aa;
}
.logohover:hover {
  font-size: 18.5px;
  box-shadow: blue;
}
