.footer {
    background-color: #0d0c0c;
}

.footer__options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    grid-column-gap: 50px;
    column-gap: 50px;
    margin-top: 10px;
}