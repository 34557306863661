* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: monospace;
}

.home,
.services,
.products,
.prueba,
.sign-up {
  display: flex;
  height: 91.8vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services, .h1 {
  text-align: center;
  background-image: url('./images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('./images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.pruebapage {
  background-image: url('./images/img-9.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('./images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

@media screen and (max-width: 960px) {
  .services, .h1  {
    font-size: 40px;
  }

  .products, h1 {
    font-size: 40px;
  }
}
