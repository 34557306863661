.hero-container {
  background: linear-gradient(0deg, rgba(2,0,36,1) 21%, rgba(85,0,170,1) 84%, rgba(85,0,170,1) 100%);
   /* background: url('/images/img-7.jpg') center center/cover no-repeat; */
  height: 91.7vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  
}

.hero-container > h1 {
  color: #fff;
  font-size: 60px;
  margin-top: -70px;
}

.hero-container > p {
    text-align: center;
    margin-top: 8px;
    color: #fff;
    font-size: 25px;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

.img-principal {
  width: 250px;
  height: 300px;
  margin-bottom: 100px;
  margin-top: -175px;
  /* animation: floating 3s ease-in-out infinite !important; */
}

/* @keyframes floating
{
  0%,100%
  {
    transform: skewY(0deg) translate(0,-10px);
  }
  
  50%
  {
    transform: skewY(0deg) translate(0,10px);
  }
} */


.linkPortfolio  {
  margin-top: 30px;
  font-size: 20px;
  color: #9827BD;
}

.linkPortfolio > img {
  margin-top: 30px;
  font-size: 30px;
  color: #9827BD;
}

.linkPortfolio:hover {
  
  color:#5500aa;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 30px;
    margin-top: -90px;
  }
}

@media screen and (max-width: 768px) {

  .navbar-logo {
    position: absolute;
    margin-left: 10px;
    left: 0;
  }

  .hero-container > h1 {
    font-size: 30px;
    margin-top: -90px;
  }

  .hero-container > p {
    font-size: 15px;
  }

  

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }

  .img-principal {
    width: 150px;
    height: 180px;
    margin-bottom: 100px;
  }

  .linkPortfolio {
    text-align: center;
    font-size: 13px;
}
}

@media screen and (max-width: 380px) {
  .hero-container > h1 {
    font-size: 25px;
    margin-top: -90px;
  }

  .hero-container > p {
    font-size: 15px;
  }

  

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }

  .img-principal {
    width: 150px;
    height: 180px;
    margin-bottom: 100px;
  }

  .linkPortfolio {
    text-align: center;
    font-size: 10px;
}
}

@media screen and (max-width: 360px) {

  .navbar-logo {
    position: absolute;
    margin-left: 10px;
    left: 0;
  }

  .hero-container > h1 {
    font-size: 25px;
    margin-top: -90px;
  }

  .hero-container > p {
    font-size: 15px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }

  .img-principal {
    width: 150px;
    height: 180px;
    margin-bottom: 100px;
  }

  .linkPortfolio {
    text-align: center;
    font-size: 10px;
}
}
